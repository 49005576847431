














































import Vue from "vue";
import { Log, TableField } from "@/types";
import i18n from "@/i18n";

export default Vue.extend({
  name: "UserTable",
  data: () => ({
    perPage: 10,
    currentPage: 1,
  }),
  props: {
    item: { type: Array, required: true },
  },
  computed: {
    totalRows(): number {
      if (this.item) {
        return this.item.length;
      } else {
        return 0;
      }
    },
    fields(): TableField[] {
      // Accessing this.$i18n.locale in this computed prop tells Vue that
      // this computed prop should update whenever this.$i18n.locale
      // changes it value, even though we don't use the value.
      const lang = this.$i18n.locale;
      // We add the following just so that minification optimizers
      // do not remove the above statement because the return value isn't used.
      if (!lang) {
        return [];
      }
      return [
        {
          key: "type",
          label: i18n.t("AdminPages.reportTable.type"),
          sortable: true,
          thClass: "b-table-sort-icon-left",
        },
        {
          key: "login_method",
          label: i18n.t("AdminPages.reportTable.login-method"),
          sortable: true,
          thClass: "b-table-sort-icon-left",
        },
        {
          key: "reference",
          label: i18n.t("AdminPages.reportTable.reference"),
          sortable: true,
          thClass: "b-table-sort-icon-left",
        },
        {
          key: "step",
          label: i18n.t("AdminPages.reportTable.step"),
          sortable: true,
          thClass: "b-table-sort-icon-left",
        },
        {
          key: "created_at",
          label: i18n.t("AdminPages.reportTable.created-at"),
          sortable: true,
          thClass: "b-table-sort-icon-left",
        },
        {
          key: "actions",
          label: "",
        },
      ];
    },
  },
  methods: {
    openInformation(data: Log) {
      if (data.device && data.remote_address) {
        this.$emit("openInformation", data);
      } else {
        (data.device = i18n.t("log.not-available")),
          (data.remote_address = i18n.t("log.not-available")),
          this.$emit("openInformation", data);
      }
    },
  },
});

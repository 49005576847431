






import Vue from "vue";
import AuthContainer from "@/components/auth/AuthContainer.vue";
import LoginForm from "@/components/auth/LoginForm.vue";

export default Vue.extend({
  name: "Login",
  components: {
    AuthContainer,
    LoginForm,
  },
});

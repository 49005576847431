import { render, staticRenderFns } from "./FailedPayments.vue?vue&type=template&id=51808e6e&scoped=true&"
import script from "./FailedPayments.vue?vue&type=script&lang=ts&"
export * from "./FailedPayments.vue?vue&type=script&lang=ts&"
import style0 from "./FailedPayments.vue?vue&type=style&index=0&id=51808e6e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51808e6e",
  null
  
)

export default component.exports








































































import Vue from "vue";
import { Payment } from "@/types/";
import { Loan } from "@/types/";
import { localizeDate } from "@/utils/helpers";
import { mapGetters } from "vuex";
import { BIconChevronLeft, BIconChevronRight } from "bootstrap-vue";
import SquareButton from "@/components/bits/SquareButton.vue";

export default Vue.extend({
  name: "FailedPayments",
  components: {
    SquareButton,
    BIconChevronLeft,
    BIconChevronRight,
  },
  data: () => ({
    activeItem: 0,
    loanSelected: null,
  }),
  props: {
    selectedLoan: { required: false, default: null },
  },
  watch: {
    selectedLoan: {
      handler: function () {
        this.loanSelected = this.selectedLoan;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      loans: "loans/getLoans",
    }),
    failedPayments(): Payment[] {
      if (this.loanSelected === null) {
        /**
         * Get not completed Loan
         */
        const dataToHold = this.loans.filter(
          (loan: Loan) => loan.repayed < loan.totalamount
        );

        let paymentArray: Payment[] = [];

        /**
         * Loop current Loan to get failed payments
         */
        dataToHold.forEach((element: any) => {
          paymentArray = this.$store.state.payments.payments.filter(
            (p: Payment) => {
              if (element.application_id === p.application_id) {
                return !["Confirmed", "Pending"].includes(p.payment_status);
              }
            }
          );
          return paymentArray;
        });

        /**
         * add ac_deal_id and application_id
         */
        paymentArray.forEach((p: Payment) => {
          const loansId = this.loans.find(
            (loan: Loan) => loan.application_id == p.application_id
          );
          p.loanId = loansId.ac_deal_id;
        });

        /**
         * @return FailedPayment array
         */
        return paymentArray;
      } else {
        /**
         * Get single Loan selected by the filter
         */
        const selectedLoan = this.loans.find(
          (loan: Loan) => loan.ac_deal_id == this.loanSelected
        );

        /**
         * @return FailedPayment array
         */
        return this.$store.state.payments.payments.filter((p: Payment) => {
          const loanId = this.loans.find(
            (loan: Loan) => loan.application_id == p.application_id
          );
          if (
            p.application_id == selectedLoan.application_id &&
            loanId.repayed < loanId.totalamount
          ) {
            return !["Confirmed", "Pending"].includes(p.payment_status);
          }
        });
      }
    },
    isForwardEnabled(): boolean {
      return this.activeItem + 1 < this.failedPayments.length;
    },
    isBackwardEnabled(): boolean {
      return this.activeItem > 0;
    },
  },
  methods: {
    setPrevious(): void {
      if (this.isBackwardEnabled) {
        this.activeItem = this.activeItem - 1;
      }
    },
    setNext(): void {
      if (this.isForwardEnabled) {
        this.activeItem = this.activeItem + 1;
      }
    },
    localizeDate(timestamp: number) {
      return localizeDate(timestamp, this.$i18n.locale);
    },
    proceedToPayment() {
      this.$emit("executePayment", this.failedPayments[this.activeItem]);
    },
  },
});

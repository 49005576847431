























import Vue from "vue";

interface DetailsButton {
  link: string;
  text: string;
}

export default Vue.extend({
  name: "DetailsCard",
  props: {
    title: { type: String, required: true },
    button: { type: Object as () => DetailsButton },
  },
});













































































































import Vue from "vue";
import PageHeading from "@/components/PageHeading.vue";
import UserTable from "@/components/adminComponents/UserTable.vue";
import { apiAxios } from "@/store/apiAxios";
import ModalContainer from "@/components/ModalContainer.vue";
import AuthService from "@/services/AuthService";
import { User } from "@/types";

let timeout = null as unknown as number;

export default Vue.extend({
  components: {
    PageHeading,
    UserTable,
    ModalContainer,
  },
  data: () => ({
    keyword: "",
    debouncedKeyword: "",
    idInvite: null as unknown as number,
    userToEdit: null as User | unknown,
    valueStatusToEdit: null as unknown as boolean,
    valueRoleToEdit: null as unknown as number,

    page: 1,
    limit: 50,

    fetched: false,

    admin: [] as User[],
  }),
  computed: {
    showFeature(): boolean {
      if (process.env.VUE_APP_SHOW_FEATURE !== "true") {
        return false;
      }

      return true;
    },
  },
  watch: {
    page: {
      handler() {
        this.fetchUsers();
      },
      immediate: true,
    },
    debouncedKeyword() {
      this.fetchUsers();
    },
  },
  methods: {
    async fetchUsers() {
      this.fetched = false;
      this.admin = await this.$store.dispatch("user/fetchUsers", {
        keyword: this.debouncedKeyword,
        page: this.page,
        limit: this.limit,
      });
      this.fetched = true;
    },

    searchUserByFilter() {
      clearTimeout(timeout);
      // Make a new timeout set to go off in 800ms
      timeout = setTimeout(() => {
        this.page = 1;
        this.debouncedKeyword = this.keyword;
      }, 700);
    },
    initActiveStatus(data: User, value: boolean) {
      this.$bvModal.show("modal-confirm-status");
      this.userToEdit = data;
      this.valueStatusToEdit = value;
    },
    initRole(data: User, value: number) {
      this.$bvModal.show("modal-confirm-role");
      this.userToEdit = data;
      this.valueRoleToEdit = value;
    },
    initInvite(id: number) {
      this.$bvModal.show("modal-confirm-email");
      this.idInvite = id;
    },
    editActiveStatus(data: User, value: boolean) {
      data.active = value;
      AuthService.updateUser(data).then(() => {
        this.$bvModal.hide("modal-confirm-status");
      });
    },
    editRole(data: User, value: number) {
      data.role_id = value;
      AuthService.updateUser(data).then(() => {
        this.$bvModal.hide("modal-confirm-role");
      });
    },
    sendInvite(id: number) {
      apiAxios
        .post("/api/admin/invite", {
          id: id,
        })
        .then(() => {
          this.$bvModal.hide("modal-confirm-email");
        });
    },
    closeRole() {
      this.$bvModal.hide("modal-confirm-role");
    },
    closeStatus() {
      this.$bvModal.hide("modal-confirm-status");
    },
    closeEmail() {
      this.$bvModal.hide("modal-confirm-email");
    },
  },
});
